import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { bottomNavigation } from "../constraints/constants";
import { Link, useLocation } from "react-router-dom";
import MyBets from "./MyBets";
import BetSlipContext from "../context/BetSlipContext";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { motion } from "framer-motion";
const BottomNavigation = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { showBet, setShowBet } = useContext(BetSlipContext);

  return (
    <>
      <div className="bottom-navigation">
        <ul className="p-sm-3 p-2 m-0 py-sm-2">
          {bottomNavigation?.map((item, index) => {
            return (
              <li
                style={{
                  position: "relative",
                }}
                // className={location.pathname === item?.link ? "active" : ""}
                onClick={() => {
                  if (item?.id == 5) {
                    if (!isEmpty(user)) {
                      setShowBet(true);
                    } else {
                      navigate("/login");
                    }
                  } else {
                    navigate(item?.link);
                  }
                }}
              >
                <span
                  style={{
                    position: "relative",
                    zIndex: "10",
                  }}
                >
                  <div className="position-relative">
                    <img src={item?.icon} alt="" />
                  </div>
                  <span style={{ fontSize: ".7857142857142857rem" }}>
                    {item?.menu}
                  </span>
                </span>
                {location.pathname === item?.link && (
                  <motion.span
                    layoutId="pill-tab"
                    transition={{ type: "spring", duration: 0.5 }}
                    style={{
                      position: "absolute",
                      inset: "0px",
                      zIndex: "0",
                      background: "#ffc800",
                      color: "black",
                      fontSize: "9px",
                      borderRadius: "10px",
                      padding: "2px 15px",
                    }}
                  ></motion.span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {showBet && <MyBets setShowBet={setShowBet} />}
    </>
  );
};

export default BottomNavigation;

// import React, { useRef, useState } from "react";
// import { motion } from "framer-motion";

// export const SlideTabsExample = () => {
//   return (
//     <div className="bg-neutral-100 py-20">
//       <SlideTabs />
//     </div>
//   );
// };

// const SlideTabs = () => {
//   const [position, setPosition] = useState({
//     left: 0,
//     width: 0,
//     opacity: 0,
//   });

//   return (
//     <ul
//       onMouseLeave={() => {
//         setPosition((pv) => ({
//           ...pv,
//           opacity: 0,
//         }));
//       }}
//       className="relative mx-auto flex w-fit rounded-full border-2 border-black bg-white p-1"
//     >
//       <Tab setPosition={setPosition}>Home</Tab>
//       <Tab setPosition={setPosition}>Pricing</Tab>
//       <Tab setPosition={setPosition}>Features</Tab>
//       <Tab setPosition={setPosition}>Docs</Tab>
//       <Tab setPosition={setPosition}>Blog</Tab>

//       <Cursor position={position} />
//     </ul>
//   );
// };

// const Tab = ({ children, setPosition }) => {
//   const ref = useRef(null);

//   return (
//     <li
//       ref={ref}
//       onMouseEnter={() => {
//         if (!ref?.current) return;

//         const { width } = ref.current.getBoundingClientRect();

//         setPosition({
//           left: ref.current.offsetLeft,
//           width,
//           opacity: 1,
//         });
//       }}
//       className="relative z-10 block cursor-pointer px-3 py-1.5 text-xs uppercase text-white mix-blend-difference md:px-5 md:py-3 md:text-base"
//     >
//       {children}
//     </li>
//   );
// };

// const Cursor = ({ position }) => {
//   return (
//     <motion.li
//       animate={{
//         ...position,
//       }}
//       className="absolute z-0 h-7 rounded-full bg-black md:h-12"
//     />
//   );
// };
