import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import BackLay from "./BackLay";
import AuthContext from "../context/AuthContext";
import { priceFormat } from "../Utils/constants";
import growth from "../assets/images/growth.svg";
import BattingIframe from "./BattingIframe";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SkeletonTheme } from "react-loading-skeleton";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
import Loader from "./Loader";
import BackLayHome from "./BackLayHome";
import SportAccordianInner from "./SportAccordianInner";
const SportAccordian = ({
  item,
  index,
  keyTime,
  activeKeys,
  handleSelect,
  type,
  score,
  activeFav,
  setActiveFav,
  odds,
  multiOddsPremium,
}) => {
  const { profileData } = useContext(AuthContext);
  const [timmer, setTimer] = useState(false);
  const [blockList, setBlockList] = useState([]);
  useEffect(() => {
    if (profileData?.marketBlockList?.length > 0) {
      setBlockList(
        profileData?.marketBlockList?.map((res) => {
          return res?.eventId;
        })
      );
    }
  }, [profileData]);
  const [showScore, setShowScore] = useState([]);

  const addToFavioute = async (
    id,
    favoriteType,
    favoriteMarketId,
    eventType
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteType=${favoriteType}&favoriteMarketId=${favoriteMarketId}&eventType=${eventType}`
    );

    if (response_users?.success) {
      if (activeFav?.includes(id)) {
        setActiveFav((prev) => {
          return prev.filter((item) => {
            return item !== id;
          });
        });
      } else {
        setActiveFav((prev) => {
          return [...prev, id];
        });
      }
    }
  };
  const [scrollHeight, setScrollHeight] = useState(40);
  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight) + 7
      : 0;
    setScrollHeight(header);
  }, []);
  
  return (
    <div key={index} className="bookmaker sport-accordian sport-home">
      <Accordion activeKey={activeKeys} onSelect={(e) => handleSelect(e, type)}>
        <Accordion.Item eventKey={item?.seriesId}>
          <Accordion.Header>
            {" "}
            <div>
              <span className="sport-counter bg-yellow ">
                {item?.matchList?.length}
              </span>
              {item?.seriesName}
            </div>
            {!activeKeys.includes(item?.seriesId) ? (
              <i class="icon-arrow-down-sencodary"></i>
            ) : (
              <i
                class="icon-arrow-down-sencodary"
                style={{ rotate: `180deg` }}
              ></i>
            )}
          </Accordion.Header>
          <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
            {item?.matchList
              ?.filter((el) => !el.eventName.includes(" T10 "))
              .map((object, index) => {
                let matchOdds = !isEmpty(
                  odds?.find((res) => {
                    return res?.mi == object?.marketId;
                  })
                )
                  ? odds?.find((res) => {
                      return res?.mi == object?.marketId;
                    })
                  : object?.matchOdss?.length > 0
                  ? { rt: object?.matchOdss, mi: object?.marketId }
                  : {} || {};
                return (
                  <SportAccordianInner
                    showScore={showScore}
                    score={score}
                    index={index}
                    block={blockList?.includes(object?.eventId) || false}
                    keyTime={keyTime}
                    setActiveFav={setActiveFav}
                    activeFav={activeFav}
                    matchOdds={matchOdds}
                    type={type}
                    object={object}
                    addToFavioute={addToFavioute}
                    setShowScore={setShowScore}
                    setTimer={setTimer}
                    scrollHeight={scrollHeight}
                    multiOddsPremium={multiOddsPremium}
                  />
                );
              })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SportAccordian;
