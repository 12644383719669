import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import SearchGames from "../../components/SearchGames";
import { motion } from "framer-motion";
const CasinoFilter = ({ casinoFilter, setCasinoFilter, header }) => {
  const [showGameSearchList, setShowGameSearchList] = useState(false);

  const list = [
    { label: "Catalog", value: "catalog" },
    { label: "Latest", value: "latest" },
    { label: "A-Z", value: "az" },
  ];
  const list2 = [
    { label: "Latest", value: "latest" },
    { label: "A-Z", value: "az" },
  ];
  return (
    <>
      <div className="casinofilter d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {(header == "live" || header == "popular" ? list : list2)?.map(
            (res) => {
              return (
                <span
                  style={{
                    position: "relative",
                  }}
                  onClick={() => setCasinoFilter(res?.value)}
                  // className={casinoFilter === res?.value && "active"}
                >
                  <p
                    style={{
                      position: "relative",
                      zIndex: "10",
                      color: "black",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    {res?.label}
                  </p>

                  {casinoFilter === res?.value && (
                    <motion.span
                      layoutId="pill-tab-casino-inner"
                      transition={{ type: "spring", duration: 0.5 }}
                      style={{
                        position: "absolute",
                        inset: "0px",
                        zIndex: "0",
                        background: "#ffc800",
                        color: "black",
                        fontSize: "13px",
                        borderRadius: "24px",
                        padding: "2px 10px",
                        width: "100%",
                      }}
                    ></motion.span>
                  )}
                </span>
              );
            }
          )}
        </div>

        <Button
          className="bg-transparent border-0 text-dark"
          onClick={() => setShowGameSearchList(true)}
        >
          <AiOutlineSearch className="fs-3" />
        </Button>
      </div>

      {showGameSearchList && (
        <SearchGames setShowGameSearchList={setShowGameSearchList} />
      )}
    </>
  );
};

export default CasinoFilter;
