import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
const GameMenu = ({ game, setGame, type, loader }) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      document.getElementById("loading").style.display = "none";
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [game]);

  return (
    <div className="game-menu">
      <ul className="p-0 mb-0">
        {list?.map((res) => {
          return (
            <li
              onClick={() => {
                setGame(res?.value);
                if (loader) {
                  document.getElementById("loading").style.display = "flex";
                }
              }}
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "relative",
                  zIndex: "10",
                  color: game == res?.value ? "black" : "white",
                }}
              >
                <div>{res?.icon}</div>
                {res?.label}{" "}
              </div>{" "}
              {game === res?.value && (
                <motion.span
                  layoutId="pill-tab-sport"
                  transition={{ type: "spring", duration: 0.5 }}
                  style={{
                    position: "absolute",
                    inset: "0px",
                    zIndex: "0",
                    background: "#ffc800",
                    color: "black",
                    fontSize: "0.9285714286rem",
                    borderRadius: "25px",
                    padding: "6px 13px",
                  }}
                ></motion.span>
              )}
            </li>
          );
        })}
        {/* {!loader && (
          <li
            onClick={() => {
              setGame("all");
              if (loader) {
                document.getElementById("loading").style.display = "flex";
              }
            }}
            className={game === "all" ? "active" : ""}
          >
            <div>
              <i class="icon-all"></i>
            </div>
            All
          </li>
        )}
        <li
          onClick={() => {
            setGame("cricket");

            if (loader) {
              document.getElementById("loading").style.display = "flex";
            }
          }}
          className={game === "cricket" ? "active" : ""}
        >
          <div>
            <i class="icon-cricket"></i>
          </div>
          Cricket
        </li>
        <li
          onClick={() => {
            setGame("soccer");
            if (loader) {
              document.getElementById("loading").style.display = "flex";
            }
          }}
          className={game === "soccer" ? "active" : ""}
        >
          <div>
            <i class="icon-soccer"></i>
          </div>
          Soccer
        </li>
        <li
          onClick={() => {
            setGame("tennis");
            if (loader) {
              document.getElementById("loading").style.display = "flex";
            }
          }}
        >
          <div>
            <i class="icon-tennis"></i>
          </div>
          Tennis
        </li> */}
      </ul>
    </div>
  );
};

const list = [
  { label: "All", value: "all", icon: <i class="icon-all"></i> },
  { label: "Cricket", value: "cricket", icon: <i class="icon-cricket"></i> },
  { label: "Soccer", value: "soccer", icon: <i class="icon-soccer"></i> },
  { label: "Tennis", value: "tennis", icon: <i class="icon-tennis"></i> },
];
export default GameMenu;
