import React, { useContext, useEffect, useState } from "react";
import LeftPanel from "../LeftPanel";
import Header from "../Header";
import RightPanel from "../RightPanel";
import Footer from "../Footer";
import { Outlet, useLocation } from "react-router-dom";
import BottomNavigation from "../BottomNavigation";
import {
  bottomNavigation,
  showMenuUsingPath,
  sidebarData,
} from "../../constraints/constants";
import BetSlipContext from "../../context/BetSlipContext";
import PlaysBet from "../PlaysBet";
import AuthContext from "../../context/AuthContext";
import { isEmpty } from "lodash";
import BookPosition from "../BookPosition";
import Message from "../Message";
import Calendar from "../Calendar";
import Loader from "../Loader";
import CurrentNews from "../CurrentNews";
import Announcement from "../Announcement";
import FooterLink from "../FooterLink";
import BetSlipParly from "../BetSlipParly";
import GameLayout from "../GameLayout";
import { Fade } from "react-awesome-reveal";
import { RxCross2 } from "react-icons/rx";
const LayoutComponent = (props) => {
  const location = useLocation();
  const { showBetDialog, message } = useContext(BetSlipContext);
  const {
    user,
    showPosition,
    showDate,
    setSearchTab,
    searchTab,
    messagelist,
    announcement,
    setAnnouncement,
    footerLink,
    showParlyBet,
    parly,
    casinoUrlHome,
    setCasinoUrlHome,
    parlyBet,
  } = useContext(AuthContext);
  const { children, visibilityType, page } = props;
  const [activePage, setActivePage] = useState(false);
  const [leaguesPage, setLeaguesPage] = useState(false);
  const [visibleBottomNavigation, setVisibleBottomNavigation] = useState(false);
  const [noShowMenu, setNoShowMenu] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setActivePage(sidebarData.some((el) => el.link === location?.pathname));
  }, []);

  useEffect(() => {
    setVisibleBottomNavigation(
      bottomNavigation.some((el) => el.link === location?.pathname)
    );
  }, []);

  useEffect(() => {
    if (location.pathname === "/leagues") {
      setLeaguesPage(true);
    }
  }, []);

  useEffect(() => {
    setNoShowMenu(
      showMenuUsingPath.some((el) => el.path === location?.pathname)
    );
  }, []);

  const [state, setState] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setState((prevState) => !prevState);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main-outer">
      <div
        className={
          visibilityType
            ? "inner-container"
            : page == "change"
            ? "inner-container login-panel"
            : "inner-container login-panel"
        }
      >
        <main id="main">
          <Outlet />

          {visibilityType && <Header />}
          {(location?.pathname == "/sports" ||
            location?.pathname == "/favourite") && (
            <CurrentNews
              message={messagelist}
              setAnnouncement={setAnnouncement}
            />
          )}
          {user?.user?.betsBlocked && state && (
            <div
              style={{
                position: "fixed",
                top: "5%",
                background: "#d0021b",
                color: "white",
                width: "100%",
                zIndex: "999999999",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "6px",
                  fontSize: "13px",
                  fontWeight: "600",
                  borderRight: "1px solid #820110",
                }}
              >
                If you have unmatched bets it would be canceled due to your
                account has been suspended. Please contact your upline.
              </div>
              <RxCross2 size={50} className="p-1" />
            </div>
          )}
          {children}

          {visibilityType && !activePage && !leaguesPage && <Footer />}
          {!sidebarData && <Footer />}
          {location.pathname == "/" ||
          location.pathname == "/login" ||
          location?.pathname?.split("/")[1] == "full-market" ||
          location?.pathname == "/change-password" ||
          location?.pathname == "/update-profile" ||
          location?.pathname == "/deposit" ? (
            ""
          ) : (
            <BottomNavigation />
          )}
          {showDate?.status && !isEmpty(showDate?.type) && <Calendar />}
          {showPosition?.status && !isEmpty(showPosition?.object) && (
            <BookPosition />
          )}
          {announcement && <Announcement data={messagelist} />}
          {message?.status && <Message />}
          {footerLink?.status && <FooterLink />}
          <Loader />
        </main>
        {showParlyBet && <BetSlipParly />}
        {showBetDialog && <PlaysBet />}
        {(location?.pathname == "/" || location?.pathname == "/casino") &&
          !isEmpty(casinoUrlHome) && (
            <GameLayout
              casinoUrlHome={casinoUrlHome}
              setCasinoUrlHome={setCasinoUrlHome}
            />
          )}
      </div>
    </div>
  );
};

export default LayoutComponent;
