import React, { useEffect, useState } from "react";
import GameCard from "../GameCard";
import GameSidebar from "../GameSidebar";
import {
  gamesLiveArray,
} from "../../constraints/constants";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const LiveGames = () => {
  const navigate = useNavigate();
  const [liveArray, setLiveArray] = useState({
    gameShows: [],
    dice: [],
    traditional: [],
    roulette: [],
    baccarat: [],
  });
  useEffect(() => {
    setHomePlayType("catalog");
    setLiveArray({
      gameShows:
        gamesLiveArray?.filter((res) => {
          return res?.type == "GAME-SHOWS" && res?.isPopular==true;
        }) || [],
      dice:
        gamesLiveArray?.filter((res) => {
          return res?.type == "DICE"&& res?.isPopular==true;
        }) || [],
      traditional:
        gamesLiveArray?.filter((res) => {
          return res?.type == "TRADITIONAL"&& res?.isPopular==true;
        }) || [],
      roulette:
        gamesLiveArray?.filter((res) => {
          return res?.type == "ROULETTE" && res?.isPopular==true;;
        }) || [],
      baccarat:
        gamesLiveArray?.filter((res) => {
          return res?.type == "BACCARAT"&& res?.isPopular==true;
        }) || [],
      cardGames:
        gamesLiveArray?.filter((res) => {
          return res?.type == "GAME-CARD";
        }) || [],
      table:
        gamesLiveArray?.filter((res) => {
          return res?.type == "TABLE";
        }) || [],
    });
  }, []);

  const [data, setData] = useState([]);
  const [homePlayType, setHomePlayType] = useState("catalog");
  const handelChange = (value) => {
    setHomePlayType(value);
    if (value == "latest") {
      setData(
        gamesLiveArray?.filter((res) => {
          return (
            res?.type == "GAME-SHOWS" ||
            res?.type == "DICE" ||
            res?.type == "TRADITIONAL" ||
            res?.type == "ROULETTE" ||
            res?.type == "BACCARAT" ||
            res?.type == "GAME-CARD" ||
            res?.type == "TABLE"
          );
        }) || []
      );
    } else if (value == "az") {
      setData((prev) => {
        return gamesLiveArray
          ?.filter((res) => {
            return (
              res?.type == "GAME-SHOWS" ||
              res?.type == "DICE" ||
              res?.type == "TRADITIONAL" ||
              res?.type == "ROULETTE" ||
              res?.type == "BACCARAT" ||
              res?.type == "GAME-CARD" ||
              res?.type == "TABLE"
            );
          })
          .sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
      });
    } else {
      setLiveArray({
        gameShows:
          gamesLiveArray?.filter((res) => {
            return res?.type == "GAME-SHOWS" && res?.isPopular==true;
          }) || [],
        dice:
          gamesLiveArray?.filter((res) => {
            return res?.type == "DICE"&& res?.isPopular==true;
          }) || [],
        traditional:
          gamesLiveArray?.filter((res) => {
            return res?.type == "TRADITIONAL"&& res?.isPopular==true;
          }) || [],
        roulette:
          gamesLiveArray?.filter((res) => {
            return res?.type == "ROULETTE" && res?.isPopular==true;;
          }) || [],
        baccarat:
          gamesLiveArray?.filter((res) => {
            return res?.type == "BACCARAT" && res?.isPopular==true;
          }) || [],
        cardGames:
          gamesLiveArray?.filter((res) => {
            return res?.type == "GAME-CARD";
          }) || [],
        table:
          gamesLiveArray?.filter((res) => {
            return res?.type == "TABLE";
          }) || [],
      });
    }
  };
  return (
    <div className="games-inner">
      <GameSidebar
        sideDataType="liveData"
        homePlayType={homePlayType}
        setHomePlayType={handelChange}
      />
      <div className="games-wrapper">
        {homePlayType == "catalog" ? (
          <>
            {liveArray?.gameShows?.length > 0 && (
              <div className="games-card-inner bg-white">
                <h2>Game Shows</h2>
                <div className="row">
                  {liveArray?.gameShows?.map((item, index) => {
                    return <GameCard key={index} res={item} />;
                  })}
                </div>
              </div>
            )}
            {liveArray?.baccarat?.length > 0 && (
              <div className="games-card-inner bg-white">
                <h2>Baccarat</h2>
                <div className="row">
                  {liveArray?.baccarat?.map((res, index) => {
                    return <GameCard key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
            {liveArray?.roulette?.length > 0 && (
              <div className="games-card-inner bg-white">
                <h2>Roulette</h2>
                <div className="row">
                  {liveArray?.roulette?.map((res, index) => {
                    return <GameCard key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
            {liveArray?.dice?.length > 0 && (
              <div className="games-card-inner bg-white">
                <h2>Dice</h2>
                <div className="row">
                  {liveArray?.dice?.map((res, index) => {
                    return <GameCard key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
            {liveArray?.traditional?.length > 0 && (
              <div className="games-card-inner bg-white">
                <h2>Traditional Games</h2>
                <div className="row">
                  {liveArray?.traditional?.map((res, index) => {
                    return <GameCard key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
            {liveArray?.cardGames?.length > 0 && (
              <div className="games-card-inner bg-white">
                <h2>Card Games</h2>
                <div className="row">
                  {liveArray?.cardGames?.map((res, index) => {
                    return <GameCard key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="games-card-inner bg-white">
            <div className="row">
              {data?.map((res, index) => {
                return <GameCard key={index} res={res} />;
              })}
            </div>
          </div>
        )}
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              navigate(`/casino?category=live`);
            }}
            style={{ background: "black", border: "none" }}
          >
            Show More <IoIosArrowForward />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LiveGames;
