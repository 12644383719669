import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "react-bootstrap";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { isEmpty, isNumber } from "lodash";
import BetSlipContext from "../context/BetSlipContext";
import useScroll from "./Scroll";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
const FancyBet = ({
  handleShowInfo,
  data,
  user,
  header,
  handelChangeFancyHeader,
  fancyHeaderKey,
  detail,
  profileData,
  setLimit,
  setShowPosition,
  scrollHeight,
  type,
  addToFavioute,
  favouriteList,
  position,
}) => {
  const naviagte = useNavigate();
  const [updatedArray, setUpdatedArray] = useState([]);
  const { setShowBetDialog, setBetSlipObject, betSlipObject, showBetDialog } =
    useContext(BetSlipContext);
  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
  };
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "fancy" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 10,
      });
    }
  }, []);

  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: "fancy",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  useEffect(() => {
    if (type == "fancyPlus") {
      // let fancyNew = data?.filter((res) => {
      //   return (
      //     res?.name?.toLowerCase().includes("odd") ||
      //     res?.name?.toLowerCase().includes("even") ||
      //     res?.name?.toLowerCase()?.includes("@") ||
      //     res?.name?.toLowerCase()?.includes("run bhav")
      //   );
      // });
      let fancyNewUpdate = data?.filter((res) => {
        return (
          res?.name?.toLowerCase().includes("lambi") ||
          res?.name?.toLowerCase().includes("@ 1")
        );
      });
      if (favouriteList?.length > 0) {
        setUpdatedArray(reorderArray(favouriteList, fancyNewUpdate));
      } else {
        setUpdatedArray(fancyNewUpdate);
      }
    } else if (type == "fancy") {
      const blacklist = ["Over", "Wkt", "@", "Run Bhav"];

      let fancyNewUpdate = data?.filter((res) => {
        return (
          !res?.name?.toLowerCase().includes("lambi") &&
          !res?.name?.toLowerCase().includes("@ 1")
        );
      });
      // let filtered = fancyNewUpdate?.filter((res) => {
      //   return res?.name?.toLowerCase()?.includes("over");
      // });
      // let not = fancyNewUpdate.filter((url) => {
      //   return !blacklist.some((e) => {
      //     return url?.name?.includes(e);
      //   });
      // });
      // let filtered2 = fancyNewUpdate?.filter((res) => {
      //   return (
      //     res?.name?.toLowerCase()?.includes("wkt") &&
      //     !res?.name?.toLowerCase()?.includes("@")
      //   );
      // });

      // let newUpdatedResult = [...filtered, ...not]?.filter((res) => {
      //   return (
      //     !res?.name?.toLowerCase().includes("odd") ||
      //     !res?.name?.toLowerCase().includes("even")
      //   );
      // });
      if (favouriteList?.length > 0) {
        setUpdatedArray(reorderArray(favouriteList, fancyNewUpdate));
      } else {
        setUpdatedArray(fancyNewUpdate);
      }
    }
  }, [data, fancyHeaderKey, type, favouriteList]);

  const reorderArray = (ids, data) => {
    const favoriteIdsSet = new Set(ids);

    const favorites = data.filter((item) => {
      return favoriteIdsSet.has(item.selectionId);
    });
    const nonFavorites = data.filter((item) => {
      return !favoriteIdsSet.has(item.selectionId);
    });

    const reorderedArray = [...favorites, ...nonFavorites];
    return reorderedArray;
  };

  const mergeAndCalculate = (betSlipObject, apiData) => {
    // console.log('apiData.betRunPosition', apiData, betSlipObject.betType, betSlipObject.odds, betSlipObject.bidPrice, betSlipObject.object.pt);

    // Iterate through each selection in the API data
    // const betRun = betSlipObject.odds;
    // let profitAmount;
    // let loseAmount;

    // if (betSlipObject.betType === 'Yes') {
    //     profitAmount = parseFloat((betSlipObject.bidPrice * betSlipObject.object.pt / 100).toFixed(2));
    //     loseAmount = parseFloat(betSlipObject.bidPrice);
    // } else if (betSlipObject.betType === 'No') {
    //     profitAmount = parseFloat(betSlipObject.bidPrice);
    //     loseAmount = parseFloat((betSlipObject.bidPrice * betSlipObject.object.pt / 100).toFixed(2));
    // }

    // // console.log('apiData.betRunPosition, profitAmount, loseAmount ', apiData.betRunPosition, profitAmount, loseAmount);

    // let mergedBetRunPosition = apiData.betRunPosition.map((position) => ({
    //     ...position,
    //     position: position.position !== null && position.position !== undefined ? position.position : 0  // Ensure no null or undefined values
    // }));

    // // console.log('mergedBetRunPosition', mergedBetRunPosition);

    // const existingIndex = mergedBetRunPosition.findIndex((item) => item.betRun === betRun);

    // if (existingIndex !== -1) {

    //     mergedBetRunPosition[existingIndex].position += (betSlipObject.betType === 'Yes') ? profitAmount : -loseAmount;

    //   } else {

    //     const newPosition = (betSlipObject.betType === 'Yes') ? profitAmount : -loseAmount;

    //     mergedBetRunPosition.push({
    //         betRun: betRun,
    //         position: newPosition
    //     });
    // }

    // console.log('mergedBetRunPosition 2', mergedBetRunPosition);

    const betRun = betSlipObject.odds;
    let profitAmount;
    let loseAmount;

    if (betSlipObject.betType === "Yes") {
      profitAmount = parseFloat(
        ((betSlipObject.bidPrice * betSlipObject.object.pt) / 100).toFixed(2)
      );
      loseAmount = -parseFloat(betSlipObject.bidPrice);
    } else if (betSlipObject.betType === "No") {
      profitAmount = parseFloat(betSlipObject.bidPrice);
      loseAmount = -parseFloat(
        ((betSlipObject.bidPrice * betSlipObject.object.pt) / 100).toFixed(2)
      );
    }

    // console.log("apiData.betRunPosition", apiData.betRunPosition);

    let mergedBetRunPosition = apiData.betRunPosition.map((position) => ({
      ...position,
      position:
        position.position !== null && position.position !== undefined
          ? position.position
          : 0,
    }));

    // console.log("mergedBetRunPosition", mergedBetRunPosition);

    const minBetRun = Math.min(
      ...mergedBetRunPosition.map((item) => item.betRun)
    );
    const maxBetRun = Math.max(
      ...mergedBetRunPosition.map((item) => item.betRun)
    );

    if (betRun > maxBetRun) {
      for (let i = maxBetRun + 1; i <= betRun; i++) {
        mergedBetRunPosition.push({ betRun: i, position: 0 });
      }
    } else if (betRun < minBetRun) {
      for (let i = betRun + 1; i <= minBetRun - 1; i++) {
        mergedBetRunPosition.push({ betRun: i, position: 0 });
      }
    }

    mergedBetRunPosition.forEach((item) => {
      if (betSlipObject.betType === "Yes") {
        if (item.betRun >= betRun) {
          item.position += profitAmount;
        } else {
          item.position += loseAmount;
        }
      } else if (betSlipObject.betType === "No") {
        if (item.betRun < betRun) {
          item.position += profitAmount;
        } else {
          item.position += loseAmount;
        }
      }
    });

    mergedBetRunPosition.sort((a, b) => a.betRun - b.betRun);

    // console.log("mergedBetRunPosition 3", mergedBetRunPosition);

    const maxLossAmount = Math.min(
      ...mergedBetRunPosition.map((item) => item.position)
    );

    // console.log("maxLossAmount", maxLossAmount);

    return maxLossAmount;
  };

  return (
    <>
      <div
        className={`sports-tab-panel fancy-sports-inner-tab position-relative w-100 `}
      >
        <div
          className="fancy-header-new"
          style={{ height: "2.142857142857143rem" }}
        >
          {header?.map((res) => {
            return (
              <div
                key={res?.type}
                onClick={() => handelChangeFancyHeader(res?.type)}
              >
                <h3 className={res?.type == fancyHeaderKey ? "active" : ""}>
                  {res?.name}
                </h3>
              </div>
            );
          })}
        </div>
        <Button
          className="position-absolute end-0 p-0 top-0 info-btn border-0"
          onClick={() => handleShowInfo()}
        >
          <BsFillInfoCircleFill />
        </Button>
      </div>
      <div className="match-odd-table bg-white py-1 px-0 mobile-match">
        {updatedArray?.length > 0 && (
          <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
            <span className="text-center w-100">No</span>
            <span className="text-center w-100">Yes</span>
          </div>
        )}
        <motion.ul
          layout // Ensures Framer Motion will animate the layout change of child items
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{ listStyleType: "none", padding: 0 }}
          className="mb-2 rounded fancy-list position-relative p-1"
        >
          {updatedArray?.length > 0 ? (
            updatedArray?.map((item, index) => {
              if (
                item?.odds?.ms == 4 ||
                item?.odds?.rt?.length == 0 ||
                item?.odds?.ms == 2 ||
                isEmpty(item?.odds?.rt)
              ) {
                return null;
              } else {
                let oldPosition = !isEmpty(
                  position?.find((res) => {
                    return res?.selectionId == item?.selectionId;
                  })
                )
                  ? position?.find((res) => {
                      return res?.selectionId == item?.selectionId;
                    })?.position
                  : "";

                let newPosition = "";
                if (oldPosition && position) {
                  let oldPositionData =
                    position?.find((res) => {
                      return res?.selectionId == item?.selectionId;
                    }) || [];

                  if (betSlipObject?.teamName === item?.name && showBetDialog) {
                    betSlipObject.betType = betSlipObject?.object?.ib
                      ? "Yes"
                      : "No";

                    newPosition =
                      oldPositionData && oldPositionData?.betRunPosition
                        ? mergeAndCalculate(betSlipObject, oldPositionData)
                        : 0;
                  }
                } else {
                  if (betSlipObject?.teamName === item?.name && showBetDialog) {
                    if (betSlipObject?.object?.ib) {
                      if (Math.abs(item?.position) > 0) {
                        let backLoss =
                          Number(betSlipObject?.bidPrice) +
                          Number(item?.position);
                        newPosition = backLoss;
                      } else {
                        let backLoss = Number(betSlipObject?.bidPrice);
                        newPosition = backLoss;
                      }
                    } else {
                      if (Math.abs(item?.position) > 0) {
                        let backProfit =
                          (betSlipObject?.bidPrice *
                            betSlipObject?.object?.pt) /
                          100;
                        newPosition =
                          Number(backProfit) + Number(item.position);
                      } else {
                        let backProfit =
                          (betSlipObject?.bidPrice *
                            betSlipObject?.object?.pt) /
                          100;
                        newPosition = backProfit;
                      }
                    }
                  }
                }
                let condition =
                  betSlipObject?.teamName === item?.name &&
                  Math.abs(newPosition) >= 0;

                return (
                  <motion.li
                    layout // This allows Framer Motion to handle layout changes
                    transition={{
                      type: "spring",
                      stiffness: 50,
                      damping: 10,
                    }}
                    key={item?.centralizedId}
                    style={{
                      scrollMarginTop: scrollHeight,
                    }}
                    ref={index == scrollId ? myRef : null}
                    className={`position-relative fancy-li ${
                      !isEmpty(
                        detail?.favourites?.find((res) => {
                          return res?.favoriteMarketId == item?.selectionId;
                        })
                      )
                        ? "top-list-move"
                        : ""
                    }`}
                  >
                    <div className="fancy-list-first">
                      <span
                        onClick={() =>
                          addToFavioute(
                            detail?.eventId,
                            "Fancy",
                            item?.selectionId
                          )
                        }
                        className={`fave  bg-star-sprit-black  start-0 ${
                          !isEmpty(
                            detail?.favourites?.find((res) => {
                              return res?.favoriteMarketId == item?.selectionId;
                            })
                          )
                            ? "animate"
                            : ""
                        }`}
                      ></span>
                      <h2>
                        {item?.name}{" "}
                        {(isNumber(oldPosition) || condition) && (
                          <span className={`d-block py-1 ${"team-red-score"}`}>
                            {isNumber(oldPosition)
                              ? `( ${Math?.abs(item?.position).toFixed(2)} )`
                              : ""}
                            {betSlipObject?.teamName === item?.name &&
                              Math.abs(newPosition) >= 0 && (
                                <span>
                                  {" "}
                                  {">"}{" "}
                                  {`( ${Math.abs(newPosition).toFixed(2)} )`}
                                </span>
                              )}
                          </span>
                        )}
                      </h2>
                    </div>
                    {isNumber(oldPosition) && (
                      <span
                        onClick={() =>
                          setShowPosition({
                            status: true,
                            object: { ...item, match: detail?.eventName },
                          })
                        }
                        style={{
                          height: "100%",
                          fontSize: ".7857142857142857rem",
                          color: "white",
                          background: "rgb(111 136 152/1)",
                          padding: ".35714285714285715rem",
                          borderRadius: "4px",
                          marginRight: "5px",
                          fontWeight: "400",
                        }}
                      >
                        Book
                      </span>
                    )}
                    <div className="lay-back-table">
                      <div
                        title={
                          item?.odds?.rt?.length > 0
                            ? item?.odds?.rt[0]?.rt
                            : ""
                        }
                        id={"lay_odds_fancy_" + index}
                        className={`lay ${
                          Number(
                            document.getElementById("lay_odds_fancy_" + index)
                              ?.title
                          ) !== item?.odds?.rt[0]?.rt
                            ? "spark-lay"
                            : ""
                        }   ${
                          betSlipObject?.teamName == item?.name &&
                          betSlipObject?.odds == item?.odds?.rt[0]?.rt &&
                          showBetDialog &&
                          betSlipObject?.betType == "No"
                            ? "lay-active"
                            : ""
                        }`}
                        onClick={() =>
                          handelChange(
                            item?.odds?.rt[0],
                            "No",
                            item?.name,
                            item,
                            index
                          )
                        }
                      >
                        <strong>{item?.odds?.rt[0]?.rt}</strong>
                        <span className="d-block">{item?.odds?.rt[0]?.pt}</span>
                      </div>
                      <div
                        title={
                          item?.odds?.rt?.length > 0
                            ? item?.odds?.rt[1]?.rt
                            : ""
                        }
                        id={"back_odds_fancy_" + index}
                        className={`back ${
                          Number(
                            document.getElementById("back_odds_fancy_" + index)
                              ?.title
                          ) !== item?.odds?.rt[1]?.rt
                            ? "spark-back"
                            : ""
                        } 
                      ${
                        betSlipObject?.teamName == item?.name &&
                        betSlipObject?.odds == item?.odds?.rt[1]?.rt &&
                        showBetDialog &&
                        betSlipObject?.betType == "Yes"
                          ? "back-active"
                          : ""
                      }
                      `}
                        onClick={() =>
                          handelChange(
                            item?.odds?.rt[1],
                            "Yes",
                            item?.name,
                            item,
                            index
                          )
                        }
                      >
                        <strong>{item?.odds?.rt[1]?.rt}</strong>
                        <span className="d-block">{item?.odds?.rt[1]?.pt}</span>
                      </div>
                    </div>
                    {(item?.odds?.ms == 9 ||
                      item?.odds?.ms == 2 ||
                      item?.odds?.ms == 3 ||
                      item?.odds?.ms == 4) && (
                      <div class="overlay-match">
                        {item?.odds?.ms == 9
                          ? "Ball Running"
                          : item?.odds?.ms == 2
                          ? "In Active"
                          : item?.odds?.ms == 3
                          ? "Suspended"
                          : item?.odds?.ms == 4
                          ? "Closed"
                          : ""}
                      </div>
                    )}
                  </motion.li>
                );
              }
            })
          ) : (
            <div className="w-100 d-flex justify-content-center">
              No Record Found
            </div>
          )}
        </motion.ul>
      </div>
    </>
  );
};

export default FancyBet;
