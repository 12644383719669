import React, { useState } from "react";

const TabSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = ["Tab 1", "Tab 2", "Tab 3"]; // Example tab names

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="tabs-container">
      <div
        className="active-bg"
        style={{
          width: `${100 / tabs.length}%`,
          transform: `translateX(${activeIndex * 100}%)`,
        }}
      ></div>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`tab ${activeIndex === index ? "active" : ""}`}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default TabSlider;