import React from "react";
import { AiOutlineAudio } from "react-icons/ai";
const CurrentNews = ({ message, setAnnouncement }) => {
  return (
    <>
      <div
        onClick={() => {
          // if (message?.length > 0) {
          setAnnouncement(true);
          // }
        }}
        className="marquee-notification d-flex"
      >
        <div className="audio-trakcer">
        <i class="icon-mic"></i>
        </div>

        {message?.length > 0 ? (
          <marquee direction="left" scrollamount={3}>
            {message?.map((res) => {
              return (
                <span style={{ fontSize: ".7857142857rem", fontWeight: "700" }}>
                  {res?.message}
                </span>
              );
            })}
          </marquee>
        ) : (
          <marquee direction="left" scrollamount={3}>
            <span style={{ fontSize: ".7857142857rem", fontWeight: "700" }}>
              --
            </span>
          </marquee>
        )}
      </div>
    </>
  );
};

export default CurrentNews;
